<template>
    <div class="mine">
        <el-card class="box-card">
            <div class="company-info flex">
                <div class="compant-logo flex">
                    <img :src="baseInfo.logo?baseInfo.logo:require('../../../assets/img/company/logo.jpg')" alt="">
                    <p @click="routeToSettings">修改logo</p>
                </div>
                <div class="main-message">
                    <p class="compant-name">{{baseInfo.companyName}}</p>
                    <div class="service">
                        <span>认证角色：</span>
                        <span class="leval">企业管理</span>
                        <span>加入时间: </span>
                        <span class="creat-time">{{baseInfo.createTime}}</span>
                        <span class="status">审核状态: </span>
                        <span class="blur" v-if="authStatus==3">审核中</span>
                        <span class="success2" v-if="authStatus==1">已通过</span>
                        <span class="danger" v-if="authStatus==2">已拒绝</span>
                    </div>
                    <div class="comindex_rz flex">
                        <div class="mobile flex">
                            <div class="pic d1"></div>
                            <!-- <div class="pic d1-active"></div> -->
                            <div class="name">手机认证</div>
                        </div>
                        <div class="card flex">
                            <div class="pic d2"></div>
                            <!-- <div class="pic d2-active"></div> -->
                            <div class="name">营业执照认证</div>
                        </div>
                        <!-- <div class="wechat flex">
                            <div class="pic d3"></div>
                            <div class="pic d3-active"></div>
                            <div class="name">微信认证</div>
                        </div>
                        <div class="email flex">
                            <div class="pic d4"></div>
                            <div class="pic d4-active"></div>
                            <div class="name">邮箱认证</div>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="signdiv">
                    <div class="layer active">立即签到</div>
                </div> -->
            </div>
        </el-card>
        <el-card class="box-card">
            <div class="uvUs flex">
                <div class="card mr q1">
                    <div class="info">
                        <p class="info-title">收到简历</p>
                        <p class="message">招聘更多优秀人才</p>
                        <p class="strong">{{infoNumber.memberResume}}</p>
                    </div>
                </div>
                <div class="card mr q2">
                     <div class="info">
                         <p class="info-title">昨日浏览</p>
                         <p class="message">立即搜索，优质人才</p>
                         <p class="strong">{{infoNumber.yesterday}}</p>
                     </div>
                </div>
                <div class="card mr q3">
                     <div class="info">
                         <p class="info-title">可用积分</p>
                         <p class="message">勤做任务，获取更多积分</p>
                         <p class="strong">{{infoNumber.integral}}</p>
                     </div>
                </div>
                <div class="card q4">
                     <div class="info">
                         <p class="info-title">招聘会预告</p>
                         <p class="message">优秀人才，面对面沟通</p>
                         <p class="strong">{{infoNumber.jobNumber}}</p>
                     </div>
                </div>
            </div>
            <el-dialog
                title="上传logo"
                :visible.sync="uploadFlag"
                width="20%">
                <div class="acenter">
                    <upload-img @changeurl="changeurl" :modeldata="baseInfo"></upload-img>
                </div>
                <!-- <upload-img @changeurl="changeurl" :modeldata="img"></upload-img> -->
                </el-dialog>
        </el-card>
        <el-card class="box-card">
            <div class="ml">
                <div class="title">推荐简历</div>
                <div class="resume-list flex" v-if="recommendResumeList.length>0">
                    <div class="resume-item flex" v-for="(item, index) in recommendResumeList" :key="`recommendResumeList${index}`">
                        <div class="avata">
                            <img :src="item.picUrl||require('../../../assets/img/company/base_logo.png')" alt="">
                        </div>
                        <div class="resume-name">
                            {{item.name}}
                        </div>
                        <div class="resume-job">
                            {{item.workExperience?item.workExperience:'暂无工作经历'}} <span class="line"> | </span>学历：{{item.education?item.education:'未知简历'}}
                        </div>
                        <div class="resume-hope">
                            想找<span class="tips">{{item.expectPost}}</span>的工作
                        </div>
                        <div class="resume-submit">
                            <el-button class="success button" @click="routeToresumeInfo(item.id)">查看简历</el-button>
                        </div>
                    </div>
                </div>
                <div class="no-data flex" v-else>
                    <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                    <p>暂无数据，去完善企业行业等<span class="tip" @click="routeTo">基本资料</span></p>
                </div>
            </div>
            
        </el-card>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
export default {
    created() {
        this.authStatus = window.localStorage.getItem('authInfoStatus')
    },
    mounted() {
        this.getMemberInfo()
        this.getInfoNumber()
        this.getRecommendResume()
    },
    data() {
        return {
            authStatus: null,
            uploadFlag: false,
            recommendResumeList: [], //简历列表
            baseInfo: { //公司信息
                logo: '', 
                companyName: '',
                createTime: ''
            },  
            resumeId: null, //简历id
            score: 0, //查看消耗积分
            infoNumber: { //数字统计
                integral: 0, //积分
                jobNumber: 0, //招聘会
                memberResume: 0, //收到简历
                yesterday: 0 //昨日浏览
            } 

        }
    },
    methods: {
        //获取企业信息
        getMemberInfo() {
            companyRequest.getMemberInfo().then((res) => {
                if (res.code === 200) {
                    this.baseInfo = res.data
                    this.baseInfo.createTime = this.baseInfo.createTime.split(' ')[0]
                }
            })
        },
        //获取数字统计
        getInfoNumber() {
            companyRequest.getInfoNumber().then((res) => {
                if (res.code === 200) {
                    this.infoNumber.integral = res.data.integral
                    this.infoNumber.jobNumber = res.data.jobNumber
                    this.infoNumber.memberResume = res.data.memberResume
                    this.infoNumber.yesterday = res.data.yesterday
                }
            })
        },
        //跳转查看详细简历
        routeToresumeInfo(id) {
            // console.log('id', id)
            this.resumeId = id
             //查询用户是否需要消耗积分
            this.checkResume().then((res) => {
                // console.log('res', res)
                this.$confirm(`此操作将消耗${this.score}积分查看简历, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                       companyRequest. checkResumeIntegral().then((res) => {
                           if (res.success) {
                               this.$router.push({
                                    path: '/company/resumeInfo',
                                    query: {
                                        id: this.resumeId
                                    }
                                })
                           }
                       }).catch((err) => {
                           this.$message.error(err)
                       })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消查看'
                        })        
                    })
            })
            // this.$router.push({
            //     path: '/company/resumeInfo',
            //     query: {
            //         id: id
            //     }
            // })
        },
        checkResume() {
            return new Promise((reslove, reject) => {
                companyRequest.checkResume({resumeId: this.resumeId}).then((res) => {
                    if (res.code === 200) {
                        this.score = res.data.number
                        if (res.data.status) { //true看过
                            this.$router.push({
                                path: '/company/resumeInfo',
                                query: {
                                    id: this.resumeId
                                }
                            })
                        } else { //没看过
                            reslove(res)
                        }
                    } else {
                        reject()
                    }
                })
            })
        },
        //跳转到账号设置
        routeToSettings() {
            this.$router.push({
                path: '/company/settings'
            })
        },
        //跳转补全企业基本资料
        routeTo() {
            this.$router.push({
                path: '/company/companyInfo'
            })
        },
        getRecommendResume() {
            companyRequest.getRecommendResume().then((res) => {
                if (res.code === 200) {
                    this.recommendResumeList = res.data
                }
            })
        },
        openUpload() {
            this.uploadFlag = true
        },
        changeurl(url) {
            this.img.url = url
            // console.log('imgurl', this.img.url)
            this.$message.success('图片上传成功')
            this.uploadFlag = false
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .mine{
        .ml{
            margin-left: 50px;
        }
        .title{
            font-size: 20px;
            color: #333333;
            margin-bottom: 41px;
            font-weight: bold;
        }
        height: 100%;
        .acenter{
            display: flex;
            justify-content: center;
        }
        .box-card{
            padding-bottom: 20px;
        }
        .company-info{
            align-items: center;
            .compant-logo{
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 180px;
                img{
                    width: 100px;
                    height: 100px;
                    border: 1px solid #eee;
                    box-shadow: 1px 2px 3px #eee;
                }
                p{
                    cursor: pointer;
                    margin-top: 10px;
                    color: $main;
                }
            }
            .signdiv{
                .layer{
                    // padding: 10px 40px;
                    width: 150px;
                    line-height: 36px;
                    text-align: center;
                    cursor: pointer;
                }
            }
            .main-message{
                width: 75%;
                .compant-name{
                    font-size: 16px;
                    line-height: 24px;
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .service{
                    padding: 15px 0 0 0;
                    span{
                        font-size: 14px;
                        // color: #666;
                        &.leval{
                            margin-right: 15px;
                            // color: $main;
                            font-weight: 700;
                        }
                        &.creat-time{
                            font-weight: 700;
                        }
                        &.status{
                            margin-left: 15px;
                        }
                        &.blur{
                            color: $blur;
                            font-weight: 700;
                        }
                        &.success2{
                             color: $success;
                             font-weight: 700;
                        }
                        &.danger{
                             color: $danger;
                             font-weight: 700;
                        }
                    }
                }
                .comindex_rz {
                    padding: 20px 0;
                    .pic{
                        width: 18px;
                        height: 18px;
                    }
                    .name{
                        margin-top: 1px;
                        margin-left: 10px;
                        color: #666;
                        font-size: 13px;
                    }
                    .mobile{
                        margin-right: 20px;
                        .d1{
                            background: url('../../../assets/img/company/qyrz.png') no-repeat -22px 0;
                        }
                        .d1-active{
                           background: url('../../../assets/img/company/qyrz.png') no-repeat 0 0; 
                        }
                    }
                    .card{
                        margin-right: 20px;
                        .d2{
                            background: url('../../../assets/img/company/qyrz.png') no-repeat -22px -36px;
                        }
                        .d2-active{
                           background: url('../../../assets/img/company/qyrz.png') no-repeat 0 -36px; 
                        }
                    }
                    .wechat{
                        margin-right: 20px;
                        .d3{
                            background: url('../../../assets/img/company/qyrz.png') no-repeat -22px -54px;
                        }
                        .d3-active{
                           background: url('../../../assets/img/company/qyrz.png') no-repeat 0 -54px; 
                        }
                    }
                    .email{
                        margin-right: 20px;
                       .d4{
                            background: url('../../../assets/img/company/qyrz.png') no-repeat -22px -18px;
                        }
                        .d4-active{
                           background: url('../../../assets/img/company/qyrz.png') no-repeat 0 -18px; 
                        } 
                    }
                }
            }
        }
        .uvUs{
            margin-left: 50px;
            .mr{
                margin-right: 20px;
            }
            .card{
                width: 324px;
                height: 100px; 
                &:hover{
                    box-shadow: 1px 2px 3px #eee;
                }
            }
            .info{
                margin-left: 20px;
                p{
                    padding: 8px 0;
                }
                .info-title{
                    font-size: 12px;
                    color: #555;
                    // margin: 0 16px;
                }
                .message{
                    color: #9fa4b0;
                    font-size: 12px;
                    height: 17px;
                    line-height: 17px;
                }
                .strong{
                    font-size: 25px;
                    color: #333;
                    font-weight: 700;
                }
            }
            .q1{
                border: 1px solid #ecf2ff;
                background: url('../../../assets/img/company/q1.png') no-repeat;
                background-size: 74px 74px;
                background-position: 100% 100%;
            }
            .q2{
                border: 1px solid #ffedc7;
                background: url('../../../assets/img/company/q2.png') no-repeat;
                background-size: 74px 74px;
                background-position: 100% 100%;
            }
            .q3{
                border: 1px solid #d6f2cc;
                background: url('../../../assets/img/company/q3.png') no-repeat;
                background-size: 74px 74px;
                background-position: 100% 100%;
            }
            .q4{
                border: 1px solid #f3e3fe;
                background: url('../../../assets/img/company/q4.png') no-repeat;
                background-size: 74px 74px;
                background-position: 100% 100%;
            }
        }
        .ml{
            flex-wrap: wrap;
            .resume-list{
                flex-wrap: wrap;
                .resume-item{
                    width: 30%;
                    box-sizing: border-box;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0; 
                    margin-right: 20px;
                    transition: all .3s;
                    &:hover{
                        transform: translateY(-3px);
                    }
                    .avata{
                        width: 100px;
                        height: 100px;
                        border-radius: 100%;
                        overflow: hidden;
                        box-shadow: 1px 1px 3px #eee;
                        margin-bottom: 15px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .resume-name{
                        font-size: 14px;
                        font-weight: 700;
                        color: #111;
                        margin-bottom: 10px;
                    }
                    .resume-job{
                        color: #666;
                        .line{
                            padding: 0 8px;
                            color: #bbb;
                        }
                    }
                    .resume-hope{
                        color: #333;
                        margin: 10px 0;
                        .tips{
                            padding: 0 5px;
                            color: $main;
                            font-weight: 700;
                        }
                    }
                    .resume-submit{
                        margin-top: 10px;
                        .button{
                            width: 130px;
                        }
                    }
                }
            }
        }
        .no-data{
            margin-top: 150px;
            flex-direction: column;
            align-items: center;
            margin-bottom: 100px;
            img{
                width: 400px;
            }
            p{
                margin-top: 20px;
                color: #666;
                .tip{
                    color: $main;
                    font-weight: 700;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }
    }
</style>